import React from 'react'
import about from '../../image/abt.png'
import './style.css'
function Habout() {
  return (
    <div className='abts'>
        <div className='habt row px-5'>
            <div className='col-md-6'>
                <img src={about} className='img-fluid'/>
            </div>
            <div className='col-md-6'>
                <h2 className='abthead pt-5'> About Us</h2>
                <h1 className='abthead1'>Your digital marketing expert</h1>
                <p className='abthead2'>DWP is an Indian growth hacking and digital marketing agency. Across four continents, we've worked with developing startups and established companies.
                <br/>

<br/>
We thrive in the areas of user acquisition, conversion rate optimization, and lead creation. We've had the pleasure of marketing businesses ranging in size from early-stage startups to large global corporations.

eCommerce, mobile apps, and Css products are some of the prominent verticals on which we've been working.

                </p>
                <button className='abtbtn btn' ><a href='/about-us'>Learn More</a></button>
                </div>
        </div>
    </div>
  )
}

export default Habout