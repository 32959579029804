import React from 'react'
import './style.css'
import CountUp from 'react-countup'
function Numbers() {
  return (
    <div className='numbers'>
        <div className='number_row row px-5'>
            <div className='number_col col-md-3'>
                <div className='number_txt'>
                    <h1><CountUp end={25}/>+</h1>
                <p>Projects Completed</p>
                </div>
            </div>
            <div className='number_col col-md-3'>
                <div className='number_txt'>

                    <h1><CountUp end={25}/>+</h1>
                <p>Happy Clients</p>
                </div>
            </div>
            <div className='number_col col-md-3'>
                <div className='number_txt'>
                    <h1><CountUp end={20}/>+</h1>
                <p>Influencers</p>
                </div>
            </div>
            <div className='number_col col-md-3'>
                <div className='number_txt'>
                    <h1><CountUp end={10}/>+</h1>
                <p>Top Brands</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Numbers