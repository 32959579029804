import React from 'react'
import icon from '../../image/digit.png'
import './style.css'

function Banner() {
  return (
    <div className='banner py-5'>
       <div className=''>
       <div className='row col-12' >
            <div className='col-md-6  py-5'> 
            <h1 className='heading'>The digital marketing agency for growth
            </h1>
            <h4 className='heading-2'>Start with a plan and finish with results.
            </h4>
            <button className='btntext btn' ><a href='/contact-us'>Contact Us</a></button>
            
            </div>
            <div className='imgs col-md-6'>
                <img src={icon} width='100%' className='img-fluid'/>
            </div>
        </div>
       </div>
       
    </div>
  )
}

export default Banner