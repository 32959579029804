import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import logo from '../../image/mlogo.png'


function Navbar() {
 
  return (
    <>
    <nav className='navbar'>
      <input type="checkbox" id="check"/>
      <label for="check" class="checkbtn">
        <i class="fa fa-bars"></i>
      </label>
      <label class="logo"><a href='/home'><img src={logo} className="img-fluid"/></a></label>
      <ul>
      <li><a href="/home">Home</a></li>
        <li><a href="/about-us">About</a></li>
        <li><a href="/services">Services</a></li>
        <li><a href="/contact-us">Contact</a></li>
      
     
      </ul>
    </nav>
    </>
  )
}

export default Navbar