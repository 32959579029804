import React from 'react'
import services from '../../image/contentmarketing.jpg'
import webdevlopment from '../../image/webdevelopment.jpg'
import appdev from '../../image/appdev.webp'
import whats from '../../image/whats.webp'

import digital from '../../image/digital.gif'
function Serv() {
  return (
    <div> <div className='services_row'>
    <div className='row px-5'>
        <div className='services col-md-4'>
            <div className='service-card card'>
                <center>
                <img src={digital} className='img-fluid' width='105px' height='105px'/>
                <h4>Digital Marketing</h4>
                <p>Digital Marketing is advertising  delivered through digital channels  such as Google, Facebook, LinkedIn,  Twitter, Instagram, Pinterest or any  other digital channel.</p>
                </center>
            </div>
            
        </div>
        <div className='services col-md-4'>
            <div className='service-card card '>
                <center>
                <img src={webdevlopment} className='img-fluid' width='145px' height='135px'/>
                <h4>Website Development</h4>
                <p>provide information about the  business. One can reach you through  the website from all over the world.</p>
                </center>
            </div>
            
        </div>
        <div className='services col-md-4'>
            <div className='service-card card'>
                <center>
                <img src={services} className='img-fluid' width='105px' height='105px'/>
                <h4>Lead Generation</h4>
                <p>We generate leads for B2B as well  as B2C customers which allows  our customers to hit their targets  with so much ease.</p>
                </center>
            </div>
            
        </div>
        <div className='services col-md-4'>
            <div className='service-card card'>
                <center>
                <img src={appdev} className='img-fluid' width='115px' height='115px'/>
                <h4>App Development</h4>
                <p>We create  innovative and user  friendly mobile apps that put life to  your ideas. We leverage the latest  technology to shape your mobile app  idea into reality.</p>
                </center>
            </div>
            
        </div>
        <div className='services col-md-4'>
            <div className='service-card card'>
                <center>
                <img src={services} className='img-fluid' width='85px' height='85px'/>
                <h4>IVR/Missed Call</h4>
                <p>Interactive Voice Response is a  technology that allows a pre-recorded  voice to interact with humans through  voice and DTMF tones input via the keypad.</p>
                </center>
            </div>
            
        </div>
        <div className='services col-md-4'>
            <div className='service-card card'>
                <center>
                <img src={whats} className='img-fluid' width='105px' height='105px'/>
                <h4>WhatsApp API</h4>
                <p>The WhatsApp Business API is a fast,  secure, and reliable way for businesses  to reach their customers all over the  world through WhatsApp.</p>
                </center>
            </div>
            
        </div>
    </div>
</div></div>
  )
}

export default Serv