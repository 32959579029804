import React from 'react'
import HServices from '../../Component/H_Services'
import Serv from '../../Component/H_Services/service'
import about from '../../image/img2.png'
import './style.css'
function About() {
  return (<>
    <div className='abt'>
        <div className='habt row px-5 '>
            <div className='col-md-6'>
                <img src={about} className='img-fluid'height='200px'/>
            </div>
            <div className='col-md-6'>
                <h2 className='abthead pt-5'> About Us</h2>
                <h1 className='abthead1'>Inspiring Ideas Like Never Before</h1>
                <p className='abthead2'>DWP is an Indian growth hacking and digital marketing agency. Across four continents, we've worked with developing startups and established companies.
                <br/>

                <br/>
                We thrive in the areas of user acquisition, conversion rate optimization, and lead creation. We've had the pleasure of marketing businesses ranging in size from early-stage startups to large global corporations.

eCommerce, mobile apps, and Css products are some of the prominent verticals on which we've been working.
<br/><br/>
Data-driven growth marketers, digital advertising gurus, and user acquisition strategists make up our team. We're more than just a digital strategy firm. We assist you at every stage of the process, from developing a plan to implementing it and pivoting as needed. </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                </div>
        </div>
     
    </div>
       <div className='abtsws'>
       <center>
         <h1>Services We Provide</h1>
         <a style={{textDecoration:"none",color: "#283550" }} href='/services'> <Serv/></a>
       </center>
     </div>
     </>
  )
}

export default About