import React from 'react';
import image from '../../image/webdevelopment.jpg'
import digi from '../../image/digi.gif'
import app from '../../image/app.png'
import what from '../../image/what.webp'
import grap from '../../image/grap.webp'
import ecom from '../../image/ecom.png'
import opp from '../../image/opp.png'
import lead from '../../image/lead.png'
import './style.css'

function ServicePage() {
  return (
    <div>
        <center>
            <h2>Servies</h2>
            <h6>Take Our Services and Grow your Business with us.</h6>
        </center>
        <div className='service_row'>
            <div className='row py-5 '>
                <div className='col-md-6'>
                 <center>   <img src={digi} className='img-fluid'/></center>
                </div>
                <div className='col-md-6 py-5'>
                    <h2>Digital Marketing
                </h2>
                <p>Services we used to provide in Digital Marketing are: 
                    <ul>
                        <li>SEO/SMO/SMM</li>
                        <li>Branding/PR</li>
                        <li>Google Adwords/PPC</li>
                        <li>Google Analytics</li>
                        <li>Affiliate Marketing</li>
                        <li>Content Marketing</li>
                        <li>Email Marketing</li>
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>                    </div>              
            </div>
            <div className='second row'>
            <div className='col-md-6 py-5'>
                    <h2> Website Development
                </h2>
                <p>We provide static a well as dynamic type of  websites for business, which are secure to use
                    <ul>
                        <li>Wordpress</li>
                        <li>HTML5</li>
                        <li>CSS3</li>
                        <li>PHP</li>
                        <li>React js</li>
                        <li>Angular js</li>
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>
                    <div className='col-md-6'>
                 <center>   <img src={image} className='img-fluid'/></center>
                </div>
            </div>
            <div className='row py-5 mt-5'>
                <div className='col-md-6'>
                 <center>   <img src={lead} className='img-fluid'/></center>
                </div>
                <div className='col-md-6 py-5'>
                    <h2>Lead Generation
                </h2>
                <p>Services we used to provide under Lead Generation are: 
                    <ul>
                        <li>Cost Per Leads (CPL)</li>
                        <li>Live Calls on IVR (LCI)</li>
                        <li>Cost Per Verified Leads (CPVL)</li>
                        <li>OTP Verified Leads (OVL)</li>
                        <li> Cost Per Visit (CPV)</li>
                        <li>Voice Verified Leads (VVL)</li>
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>              
            </div>
            <div className='second row'>
            <div className='col-md-6 py-5'>
                    <h2> App Development
                </h2>
                <p>
                    <ul>
                        <li>Mobile App</li>
                        <li>Android</li>
                        <li>IOS</li>
                        
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>
                    <div className='col-md-6'>
                 <center>   <img src={app} className='img-fluid'/></center>
                </div>
            </div>
            <div className='row py-5 mt-5'>
                <div className='col-md-6'>
                 <center>   <img src={opp} className='img-fluid'/></center>
                </div>
                <div className='col-md-6 py-5'>
                    <h2>IVR/ Missed Call
                </h2>
                <p>Services we used to provide under Lead Generation are: 
                    <ul>
                        <li>Direct Calls / DTMF Calls</li>
                        <li>Personalized Welcome Jingle</li>
                        <li>Recording & Other Logs</li>
                        <li>Unlimited Agents</li>
                        <li> Customized SMS Replies</li>
                        <li>Missed Call Services</li>
                        <li>Conduct Polls/Surveys</li>
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>              
            </div>
            <div className='second row'>
            <div className='col-md-6 py-5'>
                    <h2> WhatsApp API
                </h2>
                <p>
                    <ul>
                        <li>WhatsApp API</li>
                        <li>Chatbot Supported</li>
                        <li>Automatic Replies</li>
                        <li>Customer Support</li>
                        
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>
                    <div className='col-md-6'>
                 <center>   <img src={what} className='img-fluid'/></center>
                </div>
            </div>
            <div className='row py-5 mt-5'>
                <div className='col-md-6'>
                 <center>   <img src={grap} className='img-fluid'/></center>
                </div>
                <div className='col-md-6 py-5'>
                    <h2>GRAPHIC DESIGN
                </h2>
                <p>
                    <ul>
                        <li>LOGO DESIGN</li>
                        <li>BUSNIESS CARD</li>
                        <li>BANNER DESIGN</li>
                        <li>FACBOOK ADS DESING</li>
                        <li> WEB BANNER DESIGN</li>
                        <li>BROUCHER DESIGN</li>
                        <li>3D DESIGN</li>
                        <li>FLYER DESIGN</li>
                        <li>COMPANY PROFILE CREATION</li>
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>              
            </div>
            <div className='second row'>
            <div className='col-md-6 py-5'>
                    <h2>eCommerce Services
                </h2>
                <p>
                    <ul>
                        <li>Fully Responsible site</li>
                        <li>Proper Product Listing</li>
                        <li>Payment Gateway Integration </li>
                        <li>eCommerce SEO Service</li>
                        
                    </ul>
                </p>
                <button className='abtbtn btn'><a href='contact-us'>Contact Us</a></button>
                    </div>
                    <div className='col-md-6'>
                 <center>   <img src={ecom} className='img-fluid'/></center>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicePage