import './App.css';
import Banner from './Component/Banner';
import Footer from './Component/Footer';
import whtsapp from './image/whatsapp.png'

import Navbar from './Component/Navbar';

import ServicePage from './Component/ServicePage';
import Whyus from './Component/Whyus';
import React from 'react'
import BannerPage from './Component/Banner/banner';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from './Container/Home';
import AboutUs from './Container/About';
import Quote from './Component/Quote';
import { Redirect } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

function App() {
  return (
    <div className="Apps">
        
      <Navbar/>
    <BrowserRouter>
    <Switch>
       <Route exact path='/'  component={Home}/>
       <Route exact path='/home'  component={Home}/>
       <Route exact path='/about-us' component={AboutUs}/>
       <Route exact path='/services' component={ServicePage}/>
       <Route exact path='/contact-us' component={Quote}/>
       <Redirect path='/' component={Home}/>

     </Switch>
     </BrowserRouter>
     <Footer/>
     <div style={{width:'60px', height:'60px', position:'fixed', left:'20px', bottom:'20px'}} >
       <a href="https://wa.me/919306614347">
         <img src={whtsapp} style={{width:"60px", height:'60px'}}/>
       </a>
     </div>
     <div style={{width:'60px', height:'60px', position:'fixed', right:'20px', bottom:'20px'}} >
  <a href='tel:+91 9306614347'><i class="fa fa-phone " aria-hidden="true" style={{border:"2px solid white",
        fontSize:"20px",
         paddingLeft:"20px",
         paddingRight:"20px",
         paddingTop:"17px",
         background:"#283550",
         color:'white',
         paddingBottom:"17px",
         borderRadius:"30px",   boxShadow: "4px 4px 10px rgba(142, 167, 226, 0.5)"
}}></i>
</a>
     </div>
    {/* <BannerPage/> */}
    
    
    {/* <Banner/> */}
    {/* <MainServices/> */}
    {/* <Footer/>  */}
       {/* 
 
    <Habout/>
    <HServices/>
    <Whyus/>
 
    <Hcontact/>
    <Numbers/>
    <Quote/>
    <ServicePage/>
 */}
    
    </div>
  );
}

export default App;
