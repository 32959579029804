import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
function Footer() {
  return (
    <div className='footers'>
        <div className='footer row px-5'>
            <div className='footer_1 col-md-4'>
                
                <h4>About Us</h4>
                <p className='footer_text'>
                DWP is an Indian growth hacking and digital marketing agency. Across four continents, we've worked with developing startups and established companies.
                </p>
            </div>
            <div className='footer_2 col-md-2'>
                <h4> Quick Links</h4>
              
                  <p className='foot_opt1' > <a href='/home'> Home</a></p>
                    <p className='foot_opt2'><a href='/services'>Services</a></p>
                    <p className='foot_opt3'><a href='/about-us'>About</a></p>
                    <p className='foot_opt4'><a href='contact-us'>Contact Us</a></p>
                    
            </div>
            <div className='footer_3 col-md-3'>
                <h4>Contact Info</h4>
                <p><span></span><b>Address:</b>  Office 012 4th Floor A Building, Kolte Patil City Vista, Fountain Road, Kharadi Pune Maharashtra</p>
                <p><span></span><b>WhatsApp:</b> +91-9975263809
                <br/>   +91-7822963200
                
                
                </p>
                <p><span></span><b>Email:</b> support@dwp.in</p>
            </div>
          
            <div className='footer_2 col-md-3'>
                <h4> Quick Links</h4>
            
            <span> <i class="fa fa-instagram" aria-hidden="true"></i></span>
            <span><i class="fa fa-linkedin-square" aria-hidden="true"></i></span>
            <span><i class="fa fa-facebook-official" aria-hidden="true"></i></span>
            <span><i class="fa fa-twitter-square" aria-hidden="true"></i></span>
            </div>
            <div>

            </div>
           

        </div>
    </div>
  )
}

export default Footer