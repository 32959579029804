import React from 'react'
import sm from '../../image/menu-ic-smm.png'
import digm from '../../image/digital-marketing-icon.png'
import eco from '../../image/menuicon5.png'
import './style.css'
function MainServices() {
  return (
    <div className='mainservices'>
        <div className='mainrow row '>
        <div className='maincol col-md-4'>
            <div class="cardclr card py-4">
  <div class="card-body">
      <div className='row'>
          <div className='col-10'>   
           <h4 class="card-title">Digital Marketing </h4></div>
           <div className='col-2'><img src={digm} className='img-fluid'/></div>
      </div>

    <p class="card-text">Digital Marketing is advertising delivered across a number of digital channels like instagram, pinterest, facebook etc.</p>
    <div className='row'>
                   <div className='col-8'></div>
                   <div className='col-3 btnlink'> <a href="#" class="card-link"> </a>
                   </div>
                   </div>
  </div>
</div>
            </div>

            <div className='maincol col-md-4'>
            <div class="cardclr card py-4 ">
  <div class="card-body">
      <div className='row'>
          <div className='col-10'>   
           <h4 class="card-title">Social Media  Marketing</h4></div>
           <div className='col-2'><img src={sm} className='img-fluid'/></div>
      </div>

    <p class="card-text">Developing content for social media in order to promote your products and/or services, build community with your target audience.</p>
    <div className='row'>
                   <div className='col-8'></div>
                   <div className='col-3 btnlink'> <a href="#" class="card-link"></a>
                   </div>
                   </div>
  </div>
</div>
            </div>
           
            <div className='maincol col-md-4'>
            <div class="cardclr card py-4">
  <div class="card-body">
      <div className='row'>
          <div className='col-10'>   
           <h4 class="card-title">E-commerce solution</h4></div>
           <div className='col-2'><img src={eco} className='img-fluid'/></div>
      </div>

    <p class="card-text">We look forward to assisting you on your path to success with our solutions for optimising your store and each of your websites.</p>
    <div className='row'>
                   <div className='col-8'></div>
                   <div className='col-3 btnlink'> <a href="#" class="card-link"></a>
                   </div>
                   </div>
  </div>
</div>
            </div>
        </div>
    </div>
  )
}

export default MainServices