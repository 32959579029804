import React from 'react'
import './style.css'

import Serv from './service'

function HServices() {
  return (
    <div className='hserives'>
        <center>
        <h2>Services</h2>
        <h4> Introducing our best Servies for your Business</h4>
        </center>
      <a style={{textDecoration:"none",color: "#283550" }} href='/contact-us'> <Serv/></a>
    </div>
  )
}

export default HServices