import React from 'react'
import './style.css'
import contact from '../../image/why.png'
function Hcontact() {
  return (
    <div className='hcontact'>
    <div className='row px-5'>
        <div className='col-md-6'>
            <img src={contact} className='img-fluid'/>
        </div>
        <div className='col-md-6'>
            <div className='quoteform row py-5 '>
                <h1 className='quote'>Get Quote</h1>
                <div className='col-12 pt-4'>
                <input type='text' className='form-control' placeholder='Full Name'></input>
                </div>
                <div className='col-md-6 pt-4'>
                <input type='email' className='form-control' placeholder='Email'></input>
                </div>
                <div className='col-md-6 pt-4'>
                <input type='phone' className='form-control' placeholder='Contact Number'></input>
                </div>
                <div className='col-12 pt-4'>
                <input type='text' className='form-control' placeholder='Subject'></input>
                </div>
                <div className='col-12 pt-4'>
                <input type='textarea' className='form-control' placeholder='Message'></input>
                </div>
                <div className='bnts'><center><button className='submit_btn btn'>SUBMIT</button></center>
                  </div>
                
            </div>
            </div>
            </div>
    </div>
  )
}

export default Hcontact