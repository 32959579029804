import React from 'react'
import Banner from '../../Component/Banner'
import Habout from '../../Component/Habout'
import Hcontact from '../../Component/Hcontact'
import HServices from '../../Component/H_Services'
import MainServices from '../../Component/MainServices'
import Numbers from '../../Component/Numbers'
import Whyus from '../../Component/Whyus'

function Home() {
  return (
    <div>
        <Banner/>
        <MainServices/>
        <Whyus/>
   
        <Habout/>
        <Numbers/>
        <HServices/>
       
      
       
    </div>
  )
}

export default Home