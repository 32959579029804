import React from 'react'
import quote from '../../image/contact.jpg'
import './style.css'

function Quote() {
  return (
   <>
   <img src={quote} className='mega img-fluid' width='100% !important'/>
    <div className="contacts_form ">
      
        <center>
    <h3 className="contact_with_us pt-5 ">
        <b>Let's talk about your business
</b></h3></center>


 <div className="address px-5 py-5 d-lg-block d-none"style={{color:"black"}}><center>
            <div className="address row">
                <div className="adress col-md-4">
                    <i class="fa fa-phone fa-2x" style ={{color:"#0b5ed7"}}  aria-hidden="true"></i>
                    <p className="phone mt-3"><b>CALL US</b> <br/>
                    +91-9975263809
                 <br/>
                 +91-7822963200</p>
                </div>
                <div className="adress col-md-4">
                   <i class="fa fa-paper-plane fa-2x"  style ={{color:"#0b5ed7"}}  aria-hidden="true"></i>
                    <p className="mail mt-3"><b>E-MAIL</b><br/>
                    support@dwp.in</p>

                </div>
                  <div className="adress col-md-4">
                   <i class="fa fa-map-marker fa-2x  " style ={{color:"#0b5ed7"}} aria-hidden="true"></i>
                   <p className="addres mt-3"><b>ADDRESS</b><br/>
                   Office 012 4th Floor A Building, Kolte Patil City Vista, Fountain Road, Kharadi Pune Maharashtra</p>

                </div>
            </div>
     </center>       
        </div>
      
     
             <div className="addresss px-5 py-5 d-lg-none d-md-block" style={{color:"black"}}><center>
        

                    <div className="adress mt-2" style={{justifyContent:"space-between"}}>
                    <i class="fa fa-phone fa-2x" style ={{color:"#0b5ed7"}}  aria-hidden="true"></i>
                   <p className="addres mt-3"><b>CALL US</b><br/>
                 +91-9975263809
                 <br/>
                 +91-7822963200</p>
                </div>
                  <div className="adress mt-2"  style={{justifyContent:"space-between"}}>
                        <i class="fa fa-paper-plane fa-2x"  style ={{color:"#0b5ed7"}}  aria-hidden="true"></i>
                   <p className="addres mt-3">E-MAIL<br/>
                  support@dwp.in</p>
                </div>
                  <div className="adress mt-2">
                   <i class="fa fa-map-marker fa-2x  " style ={{color:"#0b5ed7"}} aria-hidden="true"></i>
                   <p className="addres mt-3">ADDRESS<br/>
                   Office 012 4th Floor A Building, Kolte Patil City Vista, Fountain Road, Kharadi Pune Maharashtra</p>

                {/* </div> */}
            </div>
     </center>       
        </div>
</div>

</>
  )
}

export default Quote