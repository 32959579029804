import React from 'react'
import whyus from '../../image/why.png'
import './style.css'


function Whyus() {
  return (
    <div className='why'>
        <div className='row px-5'>
        <center>
            
                <h2 className='whyus'>Why Us</h2>
                <p className='whyus-txt'>Advertise, analyze, and optimize! We do it all for you</p>
             
                <div className='why_icon row'>
                    <div className='col-md-2'>
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                        <h6>Business Growth</h6>
                    </div>
                    <div className='col-md-2'>
                    <i class="fa fa-flag-o" aria-hidden="true"></i>
                    <h6>Smart Experience</h6>
                        </div>
               
                    <div className='col-md-2'>
                    <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    <h6>Unlimited Revisions</h6>
                    </div>
                    <div className='col-md-2'>
                    <i class="fa fa-telegram" aria-hidden="true"></i>
                    <h6>Stricit Deadline</h6>
                        </div>
                        <div className='col-md-2'>
                    <i class="fa fa-handshake-o" aria-hidden="true"></i>
                    <h6>Trusted by top Brands</h6>
                    </div>
                    <div className='col-md-2'>
                    <i class="fa fa-diamond" aria-hidden="true"></i>
                    <h6>Ultimate Perfection</h6>
                </div>
                
                {/* <div className='row'> */}
                   
                        {/* </div> */}
                </div>
                </center>
            </div>
    
    </div>
  )
}

export default Whyus